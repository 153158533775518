<template>
  <div>
    <div class="mb-2" v-if="!isOrganisationFetch">
      <div v-if="organisations.length">
        <CDataTable
          :striped="striped"
          :bordered="bordered"
          :small="small"
          :items="organisations"
          :fields="fields"
          :items-per-page="3"
          pagination
          class="border px-1"
        >
        </CDataTable>
      </div>
      <h5 v-else class="h5 text-muted text-center">
        No Child Organisation Found
      </h5>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    customer_uid: {
      type: Number,
    },
    parent_org_id: {
      type: Number,
    },
  },
  data() {
    return {
      limit: 5,
      small: true,
      striped: true,
      bordered: true,
      fields: [
        {
          key: "organisation_name",
          label: "Organisation Name",
          _style: "width:30%",
        },
        {
          key: "organisation_type",
          label: "Type",
          _style: "width:20%",
        },
        {
          key: "city",
          _style: "width:10%",
        },
        {
          key: "main_contact_name",
          label: "Main Contact Name",
          _style: "width:20%;white-space:nowrap",
        },
        { key: "email", label: "Email", _style: "width:20%" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isOrganisationFetch", "organisationList"]),
    organisations() {
      return (
        (this.organisationList &&
          this.organisationList.length &&
          this.organisationList.map(({ organisation, ...rest }) => ({
            organisation_name: organisation?.name,
            organisation_type:
              organisation?.organisation_type?.organisation_type,
            main_contact_name: organisation?.main_contact?.name,
            email: organisation?.main_contact?.email,
            city: organisation?.location?.location,
          }))) ||
        []
      );
    },
  },
  methods: {
    ...mapActions(["fetchOrganisationListForCustomer"]),
    closeModal() {
      this.$emit("closeModal");
    },
  },
  mounted() {
    this.fetchOrganisationListForCustomer({
      customer_uid: this.customer_uid,
      parent_org_id: this.parent_org_id,
    });
  },
};
</script>