var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-md col-lg-10 col-md-10 customer-list pb-4 mb-4"},[(_vm.isCustomerFetch)?_c('LoadingOverlay'):_c('div',[_c('h3',{staticClass:"text-primary"},[_vm._v("Customer List")]),_c('CDataTable',{staticClass:"px-1 border",attrs:{"striped":_vm.striped,"bordered":_vm.bordered,"small":_vm.small,"items":_vm.customers,"fields":_vm.fields,"items-per-page":_vm.small ? _vm.limit : 5,"sorter":"","tableFilter":{ label: 'Search', placeholder: 'Type for search' },"pagination":""},scopedSlots:_vm._u([{key:"action",fn:function({ item, index }){return [_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"d-flex"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Edit'),expression:"'Edit'"}],staticClass:"mr-1",attrs:{"color":"primary","square":"","size":"sm"},on:{"click":function($event){return _vm.editUser(item, index)}}},[_c('i',{staticClass:"far fa-edit"})]),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseIndex === index),expression:"collapseIndex === index"},{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: 'Collapse all child Organizations!',
                placement: 'left',
                appendToBody: true,
              }),expression:"{\n                content: 'Collapse all child Organizations!',\n                placement: 'left',\n                appendToBody: true,\n              }"}],staticClass:"mr-1",attrs:{"color":"primary","square":"","variant":"outline","size":"sm","disabled":!item.is_parent},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_c('i',{staticClass:"fa fa-minus"})]),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseIndex !== index),expression:"collapseIndex !== index"},{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: 'List all child Organizations!',
                placement: 'left',
                appendToBody: true,
              }),expression:"{\n                content: 'List all child Organizations!',\n                placement: 'left',\n                appendToBody: true,\n              }"}],staticClass:"mr-1",attrs:{"color":"primary","square":"","variant":"outline","size":"sm","disabled":!item.is_parent},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_c('i',{staticClass:"fa fa-plus"})])],1)])]}},{key:"details",fn:function({item, index}){return [_c('CCollapse',{attrs:{"show":_vm.collapseIndex === index,"duration":_vm.collapseDuration}},[_c('h6',{staticClass:"p-2 text-primary"},[_vm._v(" "+_vm._s(item.institution_name)+" - Child Organizations ("+_vm._s(_vm.organisationList.length || "0")+" Record"+_vm._s(_vm.organisationList.length > 1 ? "s" : "")+") "),(_vm.isOrganisationFetch)?_c('CSpinner',{staticClass:"mx-5",staticStyle:{"width":"2rem","height":"2rem"},attrs:{"color":"primary"}}):_vm._e()],1),(_vm.organisationListModal.isShowPopup)?_c('OrganisationList',{attrs:{"isShowPopup":_vm.organisationListModal.isShowPopup,"parent_org_id":_vm.organisationListModal.parent_org_id,"customer_uid":_vm.organisationListModal.customer_uid},on:{"closeModal":_vm.organisationModalCallBack}}):_vm._e()],1)]}}])})],1),(_vm.customerEditModal.isShowPopup)?_c('CustomerEdit',{attrs:{"customerDetail":_vm.customerEditModal.data,"isShowPopup":_vm.customerEditModal.isShowPopup},on:{"modalCallBack":_vm.customerModalCallBack}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }