<template>
  <div class="container-md col-lg-10 col-md-10 customer-list pb-4 mb-4">
    <LoadingOverlay v-if="isCustomerFetch" />
    <div v-else>
      <h3 class="text-primary">Customer List</h3>
      <CDataTable
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :items="customers"
        :fields="fields"
        :items-per-page="small ? limit : 5"
        sorter
        :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
        pagination
        class="px-1 border"
      >
        <template #action="{ item, index }">
          <td class="py-2">
            <div class="d-flex">
              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                @click="editUser(item, index)"
                v-c-tooltip="'Edit'"
                ><i class="far fa-edit"></i
              ></CButton>
              <CButton
                v-show="collapseIndex === index"
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                @click="toggleDetails(item, index)"
                :disabled="!item.is_parent"
                v-c-tooltip="{
                  content: 'Collapse all child Organizations!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <i class="fa fa-minus" ></i>
              </CButton>
              <CButton
                v-show="collapseIndex !== index"
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                @click="toggleDetails(item, index)"
                :disabled="!item.is_parent"
                v-c-tooltip="{
                  content: 'List all child Organizations!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <i class="fa fa-plus"></i>
              </CButton>
            </div>
          </td>
        </template>
        <template #details="{item, index}"> 
          <CCollapse :show="collapseIndex === index" :duration="collapseDuration">
            <h6 class="p-2 text-primary"> {{ item.institution_name }} - Child Organizations ({{ organisationList.length || "0" }} Record{{
              organisationList.length > 1 ? "s" : ""
            }})
              <CSpinner
                v-if="isOrganisationFetch"
                color="primary"
                class="mx-5"
                style="width:2rem;height:2rem;"
              />
            </h6>
            <OrganisationList
              v-if="organisationListModal.isShowPopup"
              :isShowPopup="organisationListModal.isShowPopup"
              :parent_org_id="organisationListModal.parent_org_id"
              :customer_uid="organisationListModal.customer_uid"
              @closeModal="organisationModalCallBack"
            />
          </CCollapse>
        </template>
      </CDataTable>
    </div>
    <CustomerEdit
      v-if="customerEditModal.isShowPopup"
      :customerDetail="customerEditModal.data"
      :isShowPopup="customerEditModal.isShowPopup"
      @modalCallBack="customerModalCallBack"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CustomerEdit from "@/containers/Customer/EditCustomer";
import OrganisationList from "@/containers/Customer/OrganisationList";
export default {
  components: {
    CustomerEdit,
    OrganisationList,
  },
  data() {
    return {
      collapseDuration: 0,
      collapseIndex: null,
      limit: 5,
      small: true,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      fields: [
        {
          key: "institution_name",
          label: "Institution Name",
          _style: "width:30%",
        },
        {
          key: "parent",
          _style: "width:5%",
        },
        { key: "customer_type", label: "Customer Type", _style: "width:15%" },
        { key: "city", _style: "width:10%" },
        {
          key: "main_contact_name",
          label: "Main Contact Name",
          _style: "width:15%;white-space:nowrap",
        },
        { key: "email", label: "Email", _style: "width:15%" },
        {
          key: "action",
          label: "Action",
          sorter: false,
          filter: false,
          _style: "width:10%",
        },
      ],
      customerEditModal: {
        data: null,
        isShowPopup: false,
      },
      organisationListModal: {
        isShowPopup: false,
        customer_uid: null,
        parent_org_id: null,
      },
    };
  },
  computed: {
    ...mapGetters(["customerList", "isCustomerFetch", "organisationList", "isOrganisationFetch"]),
    customers() {
      return (
        (this.customerList &&
          this.customerList.length &&
          this.customerList
            .map((val) => ({
              main_contact_name: `${val.main_user?.first_name || ""} ${
                val.main_user?.surname || ""
              }`,
              email: val.main_user?.email || "--",
              institution_name: val.name || "--",
              customer_type: val.customer_type?.comments || "--",
              main_contact_id: val.main_contact_id,
              main_user_id: val.main_user_id,
              customer_uid: val?.customer_uid,
              parent_org_id: val?.main_organisation_id,
              parent: val?.main_organisation?.is_parent ? "Yes" : "No",
              is_parent: val?.main_organisation?.is_parent,
              city: val?.customer_organisation
                ? val?.customer_organisation
                    .map((e) =>
                      e?.customer_organisation_location.map(
                        (loc) => loc?.location?.location
                      )
                    )
                    .join()
                : "--",
            }))
            .reverse()) ||
        []
      );
    },
  },
  methods: {
    ...mapActions(["fetchCustomerList", "updateCustomer", "fetchOrganisationListForCustomer"]),
    toggleDetails(item, index) {
      item.toggle = true;
      if (this.collapseIndex === index) {
        this.collapseIndex = null;
        this.tooltipHide();
        return;
      }
      this.collapseIndex = index;
      this.tooltipHide();
      this.organisationListModal["isShowPopup"] = true
      this.organisationListModal["customer_uid"] = item?.customer_uid;
      this.organisationListModal["parent_org_id"] = item?.parent_org_id;
      this.fetchOrganisationListForCustomer({
        customer_uid: item.customer_uid,
        parent_org_id: item.parent_org_id,
      });
    },
    editUser(data, index) {
      this.tooltipHide();
      let customerData = this.customerList[
        this.customerList.findIndex((e) => e.main_user_id === data.main_user_id)
      ];
      this.customerEditModal.isShowPopup = true;
      this.customerEditModal.data = customerData;
      this.customerEditModal.customerID = data.customer_uid;
    },
    customerModalCallBack(action, payload) {
      if (action === false) {
        this.customerEditModal.isShowPopup = false;
        this.customerEditModal.data = null;
      } else if (action === true) {
        let appendAction = this.updateCustomer(payload);
        Promise.all([appendAction]).then((res) => {
          if (res) {
            this.customerEditModal.isShowPopup = false;
            this.customerEditModal.data = null;
            this.fetchCustomerList();
          }
        });
      }
    },
    openOrganisationModel(item, index) {
      this.tooltipHide();
      this.organisationListModal["isShowPopup"] = true;
      this.organisationListModal["customer_uid"] = item?.customer_uid;
      this.organisationListModal["parent_org_id"] = item?.parent_org_id;
    },
    organisationModalCallBack(action) {
      this.organisationListModal.isShowPopup = false;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
  },
  mounted() {
    this.fetchCustomerList();
  },
};
</script>